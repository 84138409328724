<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" static class="fixed inset-0 z-40 overflow-y-auto" @close="cancel" :open="open">
            <div class="flex items-end justify-center h-full text-center">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="flex flex-col w-full h-full max-h-full px-4 pt-5 pb-4 overflow-scroll text-left align-bottom transition-all transform bg-white shadow-xl sm:align-middle">

                        <div class=" sm:items-start">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" class="mb-5 text-lg font-medium leading-6 text-gray-900">
                                    {{ $t("configuration.editJson") }}
                                </DialogTitle>
                            </div>
                        </div>

                        <div class="mb-5 text-center py-9 grow sm:items-start sm:mt-0 sm:text-left" style="max-height:90%">
                            <JsonEditorVue class='h-full border-b-2 border-gray-200 whitespace-nowrap' v-model="config" v-bind="{ mode: 'text', mainMenuBar: false }" />
                        </div>

                        <div class=" sm:flex-row sm:justify-between">
                            <div class="sm:flex sm:flex-row-reverse">
                                <button type="button" class="inline-flex justify-center w-full h-10 px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm disabled:bg-gray-300 hover:bg-indigo-700 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" @click="$emit('approve', config)" :disabled="!isValid">
                                    {{ $t("common.action.save") }}
                                </button>
                                <button type="button" class="inline-flex justify-center w-full h-10 px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" @click="cancel" ref="cancelButtonRef">
                                    {{ $t("common.action.cancel") }}
                                </button>
                            </div>
                        </div>

                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";

import { Dialog, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

import JsonEditorVue from 'json-editor-vue'

export default {
    components: {
        Dialog,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        JsonEditorVue
    },

    props: ["open"],
    emits: ["approve", "cancel"],


    setup(props, { emit }) {

        const store = useStore();
        const device = computed(() => store.state.device.current);
        const config = ref(device.value.configV2);

        watch(device, () => {
            if (device.value)
                config.value = device.value.configV2;
        })

        const isValid = computed(() => {
            try {
                JSON.parse(config.value);
            } catch (e) {
                return false;
            }
            return true;
        });

        const cancel = () => {
            config.value = device.value.configV2
            emit('cancel')
        }

        return {
            config,
            cancel,
            isValid
        };
    },
};
</script>
<style>
.hljs .header {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
}
</style>
