import Store from "@/store";
import {Auth} from "aws-amplify";

export const AuthFilter = (async (to, from, next) => {
    await Auth.currentAuthenticatedUser({bypassCache: true})
        .then((user) => {
            Store.commit("auth/setUser", user);
            next();
        })
        .catch(async () => {
            Store.commit("auth/setUser", null);
            next();
        });
});
